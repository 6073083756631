import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import Card from '../../common/components/Card';

const Wrapper = styled.div`
  color: initial;
`;

const FinanceReports = () => {
  const itemClass = 'w-full md:w-1/2 lg:w-1/4 p-2';
  const itemTitleClass = 'text-gray-800 line-clamp-1';
  const itemTextClass = 'text-gray-600 line-clamp-2';
  return (
    <Card floating padded={false} bodyBackground="none" title="Reports">
      <div className="flex flex-col md:flex-row md:flex-wrap">
        <Link className={itemClass} to="/finance/reports/weekly-snapshot">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className={itemTitleClass}>Weekly Timesheet Snapshot</h2>
              <p className={itemTextClass}>
                Show weekly tasks and hours for all employees for a given range.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className={itemClass} to="/finance/reports/daily-snapshot">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className={itemTitleClass}>Daily Timesheet Snapshot</h2>
              <p className={itemTextClass}>
                Show daily tasks and hours for all employees for a given range.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className={itemClass} to="/finance/reports/payroll-allocation">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className={itemTitleClass}>Payroll Allocation</h2>
              <p className={itemTextClass}>
                Generate employee allocation report for payroll submission.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className={itemClass} to="/finance/reports/timeoff">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className={itemTitleClass}>Time Off Report</h2>
              <p className={itemTextClass}>
                Generate employee time off report.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className={itemClass} to="/finance/reports/quickbooks-export">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className={itemTitleClass}>Quickbooks Export</h2>
              <p className={itemTextClass}>
                Export timesheet data for the purposes of importing into Quickbooks desktop edition.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className={itemClass} to="/finance/reports/missing-timesheets">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className={itemTitleClass}>Missing Timesheets By Week</h2>
              <p className={itemTextClass}>
                Identify and list all missing timesheets for each week.
              </p>
            </Wrapper>
          </Card>
        </Link>
        <Link className={itemClass} to="/finance/reports/projects">
          <Card border padded liftOnHover>
            <Wrapper className="text-initial">
              <h2 className={itemTitleClass}>Projects</h2>
              <p className={itemTextClass}>
                Show all projects and relevant details.
              </p>
            </Wrapper>
          </Card>
        </Link>
      </div>
    </Card>
  );
};

export default FinanceReports;
