import { useState } from 'react';
import { useGlobal } from 'reactn';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import numeral from 'numeral';
import randomcolor from 'randomcolor';
import Trianglify from 'react-trianglify';
import { Drawer, Tag, Menu, Dropdown, Descriptions, Tooltip } from 'antd';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import Card from '../Card';
import Button from '../Button';
import UpdateProjectForm from '../UpdateProjectForm/UpdateProjectForm';
import { GET_MY_PROJECTS, GET_ALL_PROJECTS } from '../../../project-manager/queries';
import { showError, showSuccess } from '../../notifications';
import moment from 'moment';
import { GET_RESOURCES_BY_PROJECT_ID } from '../../../project-manager/queries';
import { GET_PROJECT_ROLES } from '../../../project-manager/components/ProjectRoles';

const Header = styled.div`
  height: 100px;
  background: ${props => props.background};
  position: relative;
  overflow: hidden;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;
const ClientName = styled.h2`
  position: absolute;
  bottom: -0.5rem;
  left: 0.5rem;
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  background: white;
  padding: 1rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;
const ProjectDescription = styled.p`
  color: initial;
  max-height: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
`;

const ARCHIVE_PROJECT = gql`
  mutation archiveProject($projectId: ID!, $archived: Boolean!) {
    archiveProject(projectId: $projectId, archived: $archived)
  }
`;

const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId)
  }
`;
const MANUAL_PROJECT_SYNC = gql`
  mutation manualProjectSync($projectId: ID!) {
    manualProjectSync(projectId: $projectId)
  }
`;

const UPDATE_BUDGET_USED = gql`
  mutation updateBudgetUsed($projectId: ID!) {
    updateBudgetUsed(projectId: $projectId)
  }
`;

const ProjectCard = ({
  project,
  truncate = false,
  liftOnHover = false,
  refetchQueries,
  showActions = false,
  children,
  history,
}) => {
  const [config] = useGlobal('config');
  const netSuiteEnabled = config['netsuite-enabled'] === 'true';
  const netSuiteUrl = config['netsuite-url'];
  const [lockEditing] = useGlobal('lockEditing');
  const [showModal, setShowModal] = useState(false);
  const [archiveProject] = useMutation(ARCHIVE_PROJECT, { refetchQueries });
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [
      {
        query: GET_MY_PROJECTS,
      },
      {
        query: GET_ALL_PROJECTS,
      },
    ],
    awaitRefetchQueries: true,
  });
  const [manualProjectSync, { loading: syncLoading }] = useMutation(MANUAL_PROJECT_SYNC, {
    variables: { projectId: project._id },
    refetchQueries: [
      ...refetchQueries,
      { query: GET_RESOURCES_BY_PROJECT_ID, variables: { projectId: project._id } },
      { query: GET_PROJECT_ROLES, variables: { projectId: project._id } },
    ],
  });

  const [updateBudgetUsed] = useMutation(UPDATE_BUDGET_USED, { refetchQueries });
  const sectors = project.sectors || [];
  return (
    <Card
      borderWrapper
      withShadow
      liftOnHover={liftOnHover}
      padded
      headerComponent={
        <Header background={randomcolor({ seed: project._id })}>
          <Trianglify
            cell_size={300}
            output="svg"
            seed={project._id}
            width={2500}
            height={500}
            x_colors={[
              randomcolor({ seed: project._id, luminosity: 'light' }),
              randomcolor({ seed: project._id, luminosity: 'dark' }),
            ]}
          />
          <ClientName>{project.client?.shortName}</ClientName>
          <div className="absolute top-0 right-0 my-2">
            {project.archived && <Tag>Archived</Tag>}
            {project.status && <Tag>{project.status}</Tag>}
          </div>
        </Header>
      }
    >
      <>
        <div>
          {showActions && (
            <div className="float-right flex gap-2">
              {lockEditing && (
                <Button
                  disabled={syncLoading}
                  onClick={async () => {
                    await manualProjectSync({ variables: { projectId: project._id } });
                  }}
                >
                  Refresh Data
                </Button>
              )}
              <Button onClick={() => setShowModal(true)}>Edit Project</Button>
              <Dropdown
                overlay={
                  <Menu>
                    <Menu.Item
                      key="archive"
                      onClick={async () => {
                        archiveProject({
                          variables: { projectId: project._id, archived: !project?.archived },
                        });
                      }}
                    >
                      {project.archived ? 'Unarchive' : 'Archive'}
                    </Menu.Item>
                    <Menu.Item
                      disabled={!project.archived || lockEditing}
                      key="delete"
                      onClick={async () => {
                        const res = await deleteProject({
                          variables: { projectId: project._id },
                        });
                        if (res.errors) {
                          const message = res.errors[0].message;
                          showError({ message });
                        } else {
                          history.replace(`/project-management/projects`);
                        }
                      }}
                    >
                      <Tooltip
                        placement="bottomLeft"
                        title={!project.archived && 'Only archived projects can be deleted'}
                      >
                        Delete
                      </Tooltip>
                    </Menu.Item>
                    <Menu.Item
                      disabled={project.archived}
                      key="recalculate"
                      onClick={async () => {
                        try {
                          await updateBudgetUsed({ variables: { projectId: project._id } });
                          showSuccess({ message: 'Budget used recalculated' });
                        } catch (e) {
                          showError({ message: 'Error recalculating budget used' });
                        }
                      }}
                    >
                      <Tooltip placement="bottomLeft" title="Recalculate budget used on project.">
                        Recalculate Budget Used
                      </Tooltip>
                    </Menu.Item>
                  </Menu>
                }
              >
                <Button shape="circle" icon="ellipsis" />
              </Dropdown>
            </div>
          )}
          <h2>
            {project.name?.length > 50 && truncate
              ? project.name.substring(0, 50 - 3) + '...'
              : project.name}
          </h2>
          {sectors.length > 0 && (
            <div className="mb-2">
              {sectors.map(sector => (
                <Tag key={sector}>{sector}</Tag>
              ))}
            </div>
          )}
          {children ? (
            children
          ) : (
            <ProjectDescription>
              {project.description?.length > 150 && truncate
                ? project.description.substring(0, 150 - 3) + '...'
                : project.description}
            </ProjectDescription>
          )}
        </div>
        <Descriptions>
          <Descriptions.Item label="Project Code">{project.projectCode}</Descriptions.Item>
          <Descriptions.Item label="Budget">
            {numeral(project.budget).format('$0,0')}
          </Descriptions.Item>
          <Descriptions.Item label="Budget Used">
            {numeral(project.budgetUsed).format('%0.0')}
          </Descriptions.Item>
          <Descriptions.Item label="Accounting Code">{`${project.accountingCode || ''} ${
            project.accountingCodeSuffix || ''
          }`}</Descriptions.Item>
          <Descriptions.Item label="Project Owner">{project.owner || ''}</Descriptions.Item>
        </Descriptions>
        {netSuiteEnabled && (
          <div className="w-full">
            <a
              href={`${netSuiteUrl}/app/accounting/project/project.nl?id=${project.integration.sourceId}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex justify-end">This project is managed by NetSuite</div>
            </a>
            <div className="flex justify-end">
              Last synced {moment(project?.syncedAt).format('LLL')}
            </div>
          </div>
        )}
        <Drawer
          title="Update Project"
          width="50%"
          visible={showModal}
          destroyOnClose
          closable
          onClose={() => setShowModal(false)}
          footer={null}
        >
          <UpdateProjectForm
            onComplete={() => setShowModal(false)}
            project={project}
            refetchQueries={refetchQueries}
          />
        </Drawer>
      </>
    </Card>
  );
};

export default withRouter(ProjectCard);
